import React, { useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import TopNavBar from '../Navigation/TopNavBar';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getToken, getUserId, isAuthenticated } from '../Auth/Utils';


const SearchResults = () => {
    const [queryParameters] = useSearchParams()
    console.log(queryParameters);
    const term = queryParameters.get('term');
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();

    const addNewColumnsSubmit = (e) => {
        e.preventDefault();
        console.log(e.target[0].value);
        // hides the popover
        document.body.click();
        setSearchResults([]);
        axios.get(
            `https://slr-rest-api-09fc4fd27d64.herokuapp.com/api/search?term=${term}&user_id=${getUserId()}&custom_column=${e.target[0].value}`,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        ).then(
            (response) => {
                console.log(response);
                setSearchResults(response.data.results);
            }
        );
    };
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">📚 Add Columns</Popover.Header>
            <Popover.Body>
                <span muted>Specify the columns you want to extract</span>
                <Form onSubmit={addNewColumnsSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Control type="text" placeholder="Specify your own attribute to search" />
                    </Form.Group>
                    <Button type="submit" hidden>
                        Hidden Submit for Enter Key
                    </Button>
                </Form>
            </Popover.Body>
        </Popover>
    );

    const TruncatedText = ({ text, maxLength }) => {
        const [expanded, setExpanded] = useState(false);

        const handleClick = () => {
            setExpanded(!expanded);
        };

        if (!text || text.length <= maxLength) {
            return <span>{text}</span>;
        }

        return (
            <span>
                {expanded ? text : `${text.slice(0, maxLength)}...`}
                <br />
                <Button variant="link" onClick={handleClick} style={{ marginLeft: '5px' }}>
                    {expanded ? 'Less' : 'More'}
                </Button>
            </span>
        );
    };

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login?redirect=/search');
            return;
        }
        axios.get(
            `https://slr-rest-api-09fc4fd27d64.herokuapp.com/api/search?term=${term}&user_id=${getUserId()}`,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        ).then((response) => {
            console.log(response);
            setSearchResults(response.data.results);
        });
    }, [term, navigate]);

    return (
        <>
            <TopNavBar />
            <Container style={{ paddingTop: '15px' }}>
                <h1>{term}</h1>

                <Row style={{ paddingTop: '15px' }}>
                    <Col xs>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose={true}>
                            <Button variant="success">📚 Add Columns</Button>
                        </OverlayTrigger>
                    </Col>
                    <Col md="auto"></Col>
                </Row>
                <br />
                {
                    searchResults.length === 0 &&
                    <Spinner animation="border" role="status" />
                }
                <div style={{ overflowX: 'auto', paddingTop: '15px' }}>
                    <Table hidden={searchResults.length === 0}>
                        <thead>
                            <tr>
                                <th>Article </th>
                                <th>Abstract</th>
                                {
                                    // get the first result and map the keys to the table headers
                                    searchResults.length > 0 &&
                                    Object.keys(searchResults[0].llmAnswers).map(
                                        key => <th>✨ {key}</th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchResults.map(item => {
                                    const llm = Object.values(item.llmAnswers).map(
                                        answer => <td> <TruncatedText text={answer} maxLength={100} /></td>);
                                    return (
                                        <tr key={item.articleId}>
                                            <td>
                                                <a href={`https://pubmed.ncbi.nlm.nih.gov/${item.articleId}`}>
                                                    {item.articleId}
                                                </a> <br />
                                                <TruncatedText text={item.title} maxLength={100} />
                                            </td>
                                            <td>
                                                <TruncatedText text={item.abstract} maxLength={100} />
                                            </td>
                                            {llm}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Container >
        </>
    );
}

export default SearchResults;
