import Container from 'react-bootstrap/Container';

import TopNavBar from '../Navigation/TopNavBar';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const NotFound = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (window.location.pathname !== '/404') {
            navigate('/404');
            return;
        }
    });
    return (
        <>
            <TopNavBar />
            <Container>
                <div>
                    <h1>Page not found or not logged in</h1>
                </div>
                <Row>
                    <Col xs={2} className="d-none d-md-block"></Col>
                    <Col>
                        <img src="lost-teddy.jpg" alt="Lost Teddy" style={{ maxHeight: '80%' }} />
                    </Col>
                    <Col xs={2} className="d-none d-md-block"></Col>
                </Row>
            </Container>
        </>
    );
};

export default NotFound;