
export function isAuthenticated() {
    if (localStorage.getItem("expires") === null || parseInt(localStorage.getItem("expires")) > Date.now()) {
        return false;
    }
    if (localStorage.getItem("access_token")) {
        return true;
    }
    return false;
}

export function getUserId() {
    return localStorage.getItem("user");
}

export function getToken() {
    return localStorage.getItem("access_token");
}

export function logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires");
    localStorage.removeItem("user");
    localStorage.removeItem("name");
    return true;
}

export function setAuthToken(data) {
    console.log(data);
    localStorage.setItem('access_token', data.token);
    localStorage.setItem('expires', data.expires);
    localStorage.setItem('user', data.user_id);
    localStorage.setItem('name', data.name);
}