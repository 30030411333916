import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

import TopNavBar from '../Navigation/TopNavBar';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';


const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [institution, setInstitution] = useState('');
    const [name, setName] = useState('');
    const [typeOfResearcher, setTypeOfResearcher] = useState('');

    const [errors, setErrors] = useState({ email: false, password: false });
    const [validated, setValidated] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email.length < 3 || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setErrors({ ...errors, email: true });
            return;
        }
        setErrors({ ...errors, email: false });
        if (password.length < 6) {
            setErrors({ ...errors, password: true });
            return;
        }
        setErrors({ ...errors, email: false, password: false });
        setValidated(true);

        try {
            // Make an HTTP request to the API endpoint
            const response = await axios.post('https://slr-rest-api-09fc4fd27d64.herokuapp.com/api/signup', {
                email,
                password,
                name,
                typeOfResearcher,
                institution
            });
            console.log(response);
            navigate('/verify-email');
            return;
        } catch (error) {
            // Handle any errors that occur during the request
            console.error(error.response.data);
            setErrorMsg(error.response.data.detail);
        }
    };
    return (
        <>
            <TopNavBar />
            <Container>
                <Row>
                    <Col className="d-none d-md-block">
                        <img src="teddy.webp" alt="Flying Smooth Teddy" style={{ paddingTop: '20px' }} />
                        <p> <i> Air searching Teddy</i></p>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center">
                        <Container className="shadow p-3 mb-5 bg-white rounded">
                            {
                                errorMsg !== '' && <Alert variant="danger">
                                    {errorMsg}
                                </Alert>
                            }
                            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name" className="mb-1"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Institution</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Institution" className="mb-1"
                                        value={institution}
                                        onChange={(e) => setInstitution(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Type of Researcher</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Type of Researcher" className="mb-1"
                                        value={typeOfResearcher}
                                        onChange={(e) => setTypeOfResearcher(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" className="mb-1"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter password" className="mb-1"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mb-3">
                                    Sign up
                                </Button>
                            </Form>
                        </Container>
                    </Col>
                </Row>
            </Container >
        </>
    );
};

export default Signup;
