import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Search from './Pages/Search';
import reportWebVitals from './reportWebVitals';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import { isAuthenticated } from './Auth/Utils';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import NotFound from './Pages/NotFound';
import SearchResults from './Pages/SearchResults';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import VerifyEmail from './Pages/VerifyEmail';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {isAuthenticated() && <Route path="/search" element={<Search />} />}
        {isAuthenticated() && <Route path="/search-results" element={<SearchResults />} />}
        <Route path="/404" element={<NotFound />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
