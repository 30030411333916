import Container from 'react-bootstrap/Container';

import TopNavBar from '../Navigation/TopNavBar';
import { Col, Row } from 'react-bootstrap';

const VerifyEmail = () => {

    return (
        <>
            <TopNavBar />
            <Container>
                <div>
                    <h1>Please verify your Email!</h1>
                </div>
                <Row>
                    <Col xs={2} className="d-none d-md-block"></Col>
                    <Col>
                        <img src="teddy.webp" alt="AirSearching Teddy" style={{ maxHeight: '80%' }} />
                    </Col>
                    <Col xs={2} className="d-none d-md-block"></Col>
                </Row>
            </Container>
        </>
    );
};

export default VerifyEmail;