import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import TopNavBar from '../Navigation/TopNavBar';
import { isAuthenticated } from '../Auth/Utils';


const Search = () => {
  const [formData, setFormData] = useState({
    searchString: '',
    populationString: '',
  });
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    console.log(formData);
    navigate(`/search-results?term=${formData.searchString}`);
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login?redirect=/search');
      return;
    }
  });

  return (
    <>
      <TopNavBar />
      <Container style={{ padding: '50px' }}>
        <Form action="/api/search" onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col>
              <Form.Group>
                <Form.Text>
                  Search will be performed on <a href="https://pubmed.ncbi.nlm.nih.gov/">PubMed</a>.
                </Form.Text>
                <Form.Control id="inputSearchString"
                  placeholder="Search as you do on PubMed."
                  onChange={(e) => setFormData({ ...formData, searchString: e.target.value })} />
              </Form.Group>
              <Button type="submit" hidden>
                Hidden Submit for Enter Key
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <Accordion flush hidden>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Search criteria</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group>
                      <br />
                      <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                          inline
                          label="Review"
                          name="group1"
                          type="checkbox"
                          id="inline-checkbox-1"
                        />
                        <Form.Check
                          inline
                          label="Meta-Analysis"
                          name="group2"
                          type="checkbox"
                          id="inline-checkbox-2"
                        />
                        <Form.Check
                          inline
                          label="Systematic Review"
                          name="group3"
                          type="checkbox"
                          id="inline-checkbox-3"
                        />
                        <Form.Check
                          inline
                          label="RCT"
                          name="group4"
                          type="checkbox"
                          id="inline-checkbox-4"
                        />
                        <Form.Check
                          inline
                          label="Longitudinal"
                          name="group5"
                          type="checkbox"
                          id="inline-checkbox-5"
                        />
                      </div>
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
              <Accordion flush hidden>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Advanced - PICOTS screening</Accordion.Header>
                  <Accordion.Body>
                    Help us narrow down you search by specifying your selection criteria

                    {['population', 'intervention', 'context', 'outcome'].map(
                      (inputType) => (
                        <Row>
                          <Col xs={1}>
                            <ToggleButtonGroup type="radio" name={`${inputType}-options`} defaultValue={1}>
                              <ToggleButton id={`${inputType}-include`} variant="outline-success" value={1}>
                                +
                              </ToggleButton>
                              <ToggleButton id={`${inputType}-exclude`} variant="outline-danger" value={3}>
                                -
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Col>
                          <Col xs={8}>
                            <Form.Group>
                              <Form.Control id={`${inputType}-input`}
                                placeholder={`Specify your ${inputType}`}
                                onChange={(e) => setFormData({ ...formData, populationString: e.target.value })} />
                              <Form.Text id={`${inputType}-helpblock`} muted>
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={1}>
                            <Button id={`button-${inputType}`}>
                              Add
                            </Button>
                          </Col>
                          <br /> <br />
                        </Row>

                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
};

export default Search;
